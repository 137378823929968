$dt-black: #232323;
$dt-white: #ffffff;
$dt-almost-white: #fafafa;
$dt-light-grey: #f2f3f3;
$dt-grey: #cccccc;
$dt-dark-grey: #777;
$dt-panel-heading: #e2e7ea;
$dt-green: #46c903;
$dt-blue: #2196f3;
$dt-orange: #ffab40;
$dt-yellow: #f5de12;
$dt-red: #f44336;
$dt-brand: #F25141;
