@import "../../colors";

.mat-mdc-menu-item {
  min-height: 28px !important;
}

.mat-menu-spacer {
  height: 1px;
  width: 100%;
  background: $dt-grey;
  margin: 6px 0;
}
