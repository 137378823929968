@import "../../colors";

table[cdk-table] {
  width: 100%;
  border-spacing: 0;
  font-size: 12px;
}

td[cdk-cell],
th[cdk-header-cell],
td[cdk-footer-cell] {
  flex: 1;
}

td[cdk-cell],
th[cdk-header-cell] {
  line-height: 1.5;
  position: relative;
}

th[cdk-header-cell] {
  font-weight: 500;
}

tr[cdk-header-row] {
  th[cdk-header-cell] {
    background-color: $dt-almost-white;
    font-weight: 500;
    padding: 11px 5px 9px 5px;
    border-right: 1px solid $dt-grey;
    border-bottom: 1px solid $dt-grey;
    flex-grow: 1;
    line-height: 12px;

    &:last-of-type {
      border-right: 0;
    }
  }
}

tr[cdk-row] {

  background-color: $dt-white;

  &.clickable {
    cursor: pointer;
  }

  &.not-active {
    opacity: 0.5;
  }

  td[cdk-cell] {
    padding: 2px 5px;
    border-right: 1px solid $dt-grey;
    border-bottom: 1px solid $dt-grey;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: $dt-white;

    &:last-of-type {
      border-right: 0;
    }
  }

  &:nth-child(odd) {
    td[cdk-cell] {
      background-color: $dt-light-grey;
    }
  }

  &:hover {
    td[cdk-cell] {
      background-color: $dt-grey;
    }
  }

  &:hover {
    td[cdk-cell] {
      background-color: $dt-grey;
    }
  }

  &:last-child {
    td[cdk-cell] {
      border-bottom: 0;
    }
  }
}
