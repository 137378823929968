@import "../../colors";

.mdc-button {

  &.btn-xxs {
    height: 16px;
    min-width: 0;
    font-size: 0.725rem;
    line-height: 0.725;
    padding: 0 0.325rem;

    > .mat-mdc-button-touch-target {
      height: 16px;
    }
  }

  &.btn-xs {
    height: 21px;
    min-width: 0;
    font-size: 0.725rem;
    padding: 0 0.325rem;

    > .mat-mdc-button-touch-target {
      height: 21px;
    }
  }

  &.btn-sm {
    height: 26px;
    min-width: 0;
    font-size: 0.825rem;
    padding: 0 0.625rem;

    > .mat-mdc-button-touch-target {
      height: 26px;
    }
  }

  &.btn-lg {
    height: 48px;
    min-width: 0;
    font-size: 1.225rem;
    padding: 0 1.625rem;

    > .mat-mdc-button-touch-target {
      height: 48px;
    }
  }

  &.btn-blue:not(:disabled) {
    color: $dt-white;
    background-color: $dt-blue;
  }

  &.btn-orange:not(:disabled) {
    color: $dt-black;
    background-color: $dt-orange;
    border: 1px solid $dt-black;
  }

  &.btn-red:not(:disabled) {
    color: $dt-white;
    background-color: $dt-red;
  }

  &.btn-green:not(:disabled) {
    color: $dt-white;
    background-color: $dt-green;
  }

  &.btn-yellow:not(:disabled) {
    color: $dt-black;
    background-color: $dt-yellow;
    border: 1px solid $dt-black;
  }

  &.btn-black:not(:disabled) {
    color: $dt-almost-white;
    background-color: $dt-black;
  }
}

.mdc-icon-button {
  &.btn-xs {
    width: 24px;
    height: 24px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    > .mat-mdc-button-touch-target {
      width: 24px;
      height: 24px;
    }
  }

  &.btn-sm {
    width: 30px;
    height: 30px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    > .mat-mdc-button-touch-target {
      width: 30px;
      height: 30px;
    }
  }

  &.btn-black:not(:disabled) {
    color: $dt-black;
    background-color: lighten($dt-black, 75%);
  }

  &.btn-blue:not(:disabled) {
    color: $dt-blue;
    background-color: lighten($dt-blue, 42%);
  }
}

a {
  &.mdc-button {
    text-decoration: none;
  }
}
