@import "colors";

.dt-text {
  font-size: 1rem;
  line-height: 1rem;
  margin: 0;
  padding: 0;
  font-weight: 400;

  &.dt-text-left {
    text-align: left;
  }

  &.dt-text-right {
    text-align: right;
  }

  &.dt-text-center {
    text-align: center;
  }

  &.dt-text-justify {
    text-align: justify;
  }

  &.dt-text-sm {
    font-size: 0.925rem;
  }

  &.dt-text-lg {
    font-size: 1.125rem;
  }

  &.dt-text-xl {
    font-size: 1.325rem;
  }

  &.dt-text-semi {
    font-weight: 500;
  }

  &.dt-text-bold {
    font-weight: 600;
  }

  &.dt-break-spaces {
    white-space: break-spaces;
  }

  &.dt-color-dark-grey {
    color: $dt-dark-grey
  }
}
