html {
  --mdc-radio-state-layer-size: 32px;
}
@import "./material/material";
@import "./material/reset/menu";
@import "./material/reset/form-field";
@import "./material/reset/button";
@import "./material/reset/icon";
@import "./material/reset/snackbar";
@import "./material/reset/cdk-table";
@import "./material/reset/expansion-panel";
@import "typography";
@import "colors";

/* You can add global styles to this file, and also import other style files */
html {
  font-size: 14px;
}

html, body {
  height: 100%;
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  margin: 0;
  color: $dt-black;
  background-color: $dt-white;
}

img {
  vertical-align: middle;
}

a {
  color: $dt-black;
  text-decoration: none;

  &:hover {
    color: $dt-brand;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.container {
  width: 100%;
  padding: 0 12px;
  box-sizing: border-box;
}

.w-full {
  width: 100%;
}

.padding-6 {
  padding: 6px;
}

.padding-12 {
  padding: 12px;
}

.padding-0-12 {
  padding: 0 12px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mt-12 {
  margin-top: 12px;
}

.mb-24 {
  margin-bottom: 24px;
}

.border-bottom {
  border-bottom: 1px solid $dt-dark-grey;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.no-data {
  padding: 24px;
  text-align: center;
}

.alert-box {
  padding: 12px;
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
}

.alert-box-warning {
  background: #ffd2a4;
  color: #804100;
}

@media(min-width: 1025px) {
  .container {
    width: 961px;
    margin: 0 auto;
  }
}

@media(min-width: 1199px) {
  .container {
    width: 1140px;
    margin: 0 auto;
  }
}
