@mixin MatIconSize($iconSize) {
  width: $iconSize !important;
  height: $iconSize !important;
  font-size: $iconSize !important;

  svg {
    width: $iconSize !important;
    height: $iconSize !important;
  }
}

@mixin MatIconButtonSize($name, $buttonSize, $iconSize) {
  .icon-#{$name} {
    @include MatIconSize($iconSize);
  }

  .btn-icon-#{$name} {
    width: $buttonSize !important;
    height: $buttonSize !important;
    padding: 0 !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;

    & > *[role=img] {
      @include MatIconSize($iconSize);
    }

    .mat-mdc-button-touch-target {
      width: $buttonSize !important;
      height: $buttonSize !important;
    }
  }
}

@include MatIconButtonSize('sm', 24px, 20px);
@include MatIconButtonSize('xs', 24px, 18px);
@include MatIconButtonSize('xxs', 24px, 16px);
