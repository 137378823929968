.mat-mdc-form-field {
  &.mat-mdc-no-subscript {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
  &.yellow-border > div {
    border: 5px solid #f5de12 !important;
    border-radius: 4px;
  }
}
