@import "../../colors";

.mat-mdc-snack-bar-container {
  &.notification-info {
    > div {
      background-color: $dt-blue;
    }
  }

  &.notification-success {
    > div {
      background-color: $dt-green;
    }
  }

  &.notification-error {
    > div {
      background-color: $dt-red;
    }
  }

  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: $dt-white !important;
  }
}
